import { useContext } from 'react'
import { Button, useMediaQuery } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useBrowser } from '../hooks/useBrowser.js'

import { InstallIcon } from './icons/InstallIcon.js'
import { WormholeContext } from './WormholeContext.js'

export const InstallButton = props => {
  const { t } = useTranslation()
  const [isStandalone] = useMediaQuery('(display-mode: standalone)')
  const { isInstallable, showInstallPrompt } = useContext(WormholeContext)

  const { os } = useBrowser()

  if (isStandalone) {
    return null
  }

  if (!isInstallable) {
    return null
  }

  let message = t('installButton.message.default')
  if (os === 'ios') {
    message = t('installButton.message.ios')
  } else if (os === 'android') {
    message = t('installButton.message.android')
  } else if (os === 'mac') {
    message = t('installButton.message.mac')
  } else if (os === 'windows') {
    message = t('installButton.message.windows')
  } else if (os === 'linux') {
    message = t('installButton.message.linux')
  }

  return (
    <Button
      onClick={showInstallPrompt}
      leftIcon={<InstallIcon boxSize={4} />}
      {...props}
    >
      {message}
    </Button>
  )
}
