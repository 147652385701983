import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { FiGlobe } from 'react-icons/fi'
import { useTranslation, Trans } from 'react-i18next'

import { supportedLanguages } from '../lib/i18n.js'
import { siteDiscord } from '../config.js'

import { useBreakpointValue } from '../hooks/useBreakpointValue.js'

import { Link } from './Link.js'

export const LanguageSelect = ({ ...rest }) => {
  const { t, i18n } = useTranslation()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const currentLanguageName = supportedLanguages[i18n.language].name

  const setLanguage = code => {
    if (code !== i18n.language) {
      i18n.changeLanguage(code)
    }
    onClose()
  }

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Icon as={FiGlobe} boxSize={4} />}
        size='sm'
      >
        {currentLanguageName}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior='inside'
        size={useBreakpointValue(['md', null, '2xl'])}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('languageSelect.choose')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6}>
              <SimpleGrid columns={[2, null, 3]} spacing={4}>
                {Object.entries(supportedLanguages).map(
                  ([code, { name, title }]) => (
                    <Button
                      key={code}
                      onClick={() => setLanguage(code)}
                      variant={code === i18n.language ? 'solid' : 'ghost'}
                      title={title}
                    >
                      {name}
                    </Button>
                  )
                )}
              </SimpleGrid>
              <Text fontSize='sm' color='gray.400' textAlign='center'>
                <Trans
                  t={t}
                  i18nKey='languageSelect.helpUsTranslate'
                  components={{
                    discordLink: (
                      <Link
                        href={siteDiscord}
                        textDecoration='underline'
                        showExternalIcon={false}
                      />
                    )
                  }}
                />
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  )
}
