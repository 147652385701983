import { Box, Container, Wrap } from '@chakra-ui/react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { siteDiscord, siteTwitterUrl } from '../config.js'

import { ButtonLink } from './ButtonLink.js'
import { InstallButton } from './InstallButton.js'
import { LanguageSelect } from './LanguageSelect.js'

export const Footer = memo(({ maxW, maxWidth, ...rest }) => {
  const { t } = useTranslation()

  return (
    <Box
      pt={3}
      pb='calc(var(--chakra-space-3) + env(safe-area-inset-bottom))'
      pl='env(safe-area-inset-left)'
      pr='env(safe-area-inset-right)'
      {...rest}
    >
      <Container
        as={Wrap}
        spacing={4}
        align='center'
        justify='center'
        maxW={maxW ?? maxWidth}
        shouldWrapChildren
      >
        <Wrap spacing={1} justify='center' shouldWrapChildren>
          <FooterLink href='/about'>{t('footer.whyWeBuiltThis')}</FooterLink>
          <FooterLink href='/faq'>{t('footer.faq')}</FooterLink>
          <FooterLink href='/roadmap'>{t('footer.roadmap')}</FooterLink>
          <FooterLink href='/security'>{t('footer.security')}</FooterLink>
          <FooterLink href='/legal'>{t('footer.legal')}</FooterLink>
          <FooterLink href={siteTwitterUrl}>{t('footer.twitter')}</FooterLink>
          <FooterLink href={siteDiscord}>{t('footer.discord')}</FooterLink>
          <FooterLink href='https://socket.dev'>
            {t('footer.protectedBySocket')}
          </FooterLink>
        </Wrap>
        <Wrap order={[-1, null, 'inherit']} justify='center' shouldWrapChildren>
          <Box display={['block', null, 'none']}>
            <InstallButton size='sm' variant='outline' />
          </Box>
          <LanguageSelect />
        </Wrap>
      </Container>
    </Box>
  )
})

const FooterLink = props => (
  <ButtonLink
    prefetch={false}
    variant='ghost'
    size='sm'
    showExternalIcon={false}
    {...props}
  />
)
