import { useRef, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'

import {
  Button,
  Box,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Heading,
  Stack,
  Text
} from '@chakra-ui/react'

import { useToast, useToastError } from '../hooks/useToast.js'

import { fetcher } from '../lib/fetcher.js'
import { useTranslation } from 'react-i18next'

export const NewsletterSignup = props => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState('')

  const toast = useToast()
  const toastError = useToastError()

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const handleInputChange = event => {
    setEmail(event.target.value)
  }

  const handleFormSubmit = async event => {
    event.preventDefault()

    try {
      await fetcher.post('/api/subscribe', {
        body: {
          email
        },
        retry: true
      })
    } catch (err) {
      toastError(err, {
        title: t('newsletterSignup.errorAddingToMailingList')
      })
      return
    }

    setEmail('')
    close()
    toast({
      title: t('newsletterSignup.thanksForJoining')
    })
  }

  return (
    <Box>
      <Popover
        initialFocusRef={inputRef}
        isOpen={isOpen}
        onOpen={open}
        onClose={close}
        closeOnBlur
      >
        <PopoverTrigger>
          <Button leftIcon={<Icon as={AiOutlineMail} boxSize={5} />} {...props}>
            {t('newsletterSignup.getProductUpdates')}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          px={2}
          py={4}
          color='white'
          bg='gray.800'
          borderColor='gray.700'
        >
          <PopoverHeader fontWeight='bold' border='0'>
            <Heading size='md'>{t('newsletterSignup.popoverTitle')}</Heading>
          </PopoverHeader>
          <PopoverArrow bg='gray.800' />
          <PopoverCloseButton mt={2} />
          <PopoverBody>
            <Stack spacing={4}>
              <Text>{t('newsletterSignup.popoverBody')}</Text>
              <form onSubmit={handleFormSubmit}>
                <Stack direction='row'>
                  <Input
                    size='sm'
                    value={email}
                    type='email'
                    autoComplete='email'
                    isRequired
                    onChange={handleInputChange}
                    ref={inputRef}
                    borderColor='whiteAlpha.600'
                    _hover={{
                      borderColor: 'whiteAlpha.800'
                    }}
                    _placeholder={{
                      color: 'whiteAlpha.600'
                    }}
                    placeholder={t('newsletterSignup.emailAddress')}
                  />
                  <Box>
                    <Button size='sm' type='submit'>
                      {t('newsletterSignup.submit')}
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
