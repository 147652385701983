import {
  Box,
  Collapse,
  Container,
  Flex,
  Heading,
  Icon,
  IconButton,
  Img,
  Wrap
} from '@chakra-ui/react'
import { AiOutlineSend, AiOutlineClose } from 'react-icons/ai'
import { FiMenu } from 'react-icons/fi'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { siteTitle, siteLogo } from '../config.js'
import { deepEqual } from '../lib/deep-equal.js'

import { useBrowser } from '../hooks/useBrowser.js'
import { useBreakpointValue } from '../hooks/useBreakpointValue.js'
import { useScroll } from '../hooks/useScroll.js'

import { ButtonLink } from './ButtonLink.js'
import { InstallButton } from './InstallButton.js'
import { Link } from './Link.js'
import { NewsletterSignup } from './NewsletterSignup.js'

export const Header = memo(({ buttons, maxW, maxWidth, ...rest }) => {
  const { scrollY } = useScroll()
  const [isOpen, setIsOpen] = useState(false)
  const isMobileBreakpoint = useBreakpointValue([true, true, false])

  // The percent that the header is visible, from 0 to 1
  const headerVisibility =
    isOpen && isMobileBreakpoint ? 1 : Math.max(0, Math.min(1, scrollY / 150))

  return (
    <Box
      position='sticky'
      zIndex={10}
      top={0}
      pt='max(env(safe-area-inset-top), var(--chakra-space-4))'
      pb={4}
      pl='env(safe-area-inset-left)'
      pr='env(safe-area-inset-right)'
      align='center'
      style={{
        backgroundColor: `rgba(0, 0, 0, ${headerVisibility * 0.3})`,
        backdropFilter:
          (isOpen || scrollY > 0) && `blur(${headerVisibility * 10}px)`,
        WebkitBackdropFilter:
          (isOpen || scrollY > 0) && `blur(${headerVisibility * 10}px)`
      }}
      {...rest}
    >
      <Container
        as={Flex}
        justify='space-between'
        align='center'
        maxW={maxW ?? maxWidth}
      >
        <Logo />

        <MenuToggle
          toggle={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          display={['block', null, 'none']}
        />

        <Box display={['none', null, 'block']}>
          <Wrap direction='row' align='center' justify='flex-end'>
            <HeaderButtons buttons={buttons} />
          </Wrap>
        </Box>
      </Container>

      <Collapse in={isOpen && isMobileBreakpoint} unmountOnExit>
        <Wrap justify='center' mt={6} mx={4}>
          <HeaderButtons buttons={buttons} justify='center' />
        </Wrap>
      </Collapse>
    </Box>
  )
}, deepEqual)

const HeaderButtons = ({ buttons, ...rest }) => {
  const { t } = useTranslation()
  return (
    <>
      <InstallButton variant='outline' size='sm' />
      <NewsletterSignup
        size='sm'
        variant={buttons?.length === 0 ? 'solid' : 'outline'}
      />
      {buttons == null && (
        <ButtonLink
          size='sm'
          href='/'
          leftIcon={<Icon as={AiOutlineSend} boxSize={5} />}
        >
          {t('header.sendFiles')}
        </ButtonLink>
      )}
      {buttons}
    </>
  )
}

const Logo = props => {
  const { os } = useBrowser()

  return (
    <Link
      href='/'
      _hover={{
        textDecoration: 'none'
      }}
      ps={1}
    >
      <Flex align='center'>
        <Img
          src={siteLogo}
          alt={siteTitle}
          height={10}
          width={10}
          htmlWidth={40}
          htmlHeight={40}
          display='block'
        />
        <Heading
          as='h1'
          size='lg'
          fontWeight={os === 'windows' ? 'bold' : 'extrabold'}
          ms={3}
          textShadow='rgba(0, 0, 0, 0.5) 0px 0px 2px'
          color='white'
          translate='no'
        >
          {siteTitle}
        </Heading>
      </Flex>
    </Link>
  )
}

const MenuToggle = ({ toggle, isOpen, ...rest }) => (
  <IconButton
    onClick={toggle}
    variant={isOpen ? 'outline' : 'ghost'}
    size='md'
    aria-label='Main Menu'
    icon={
      isOpen ? (
        <Icon as={AiOutlineClose} boxSize={6} />
      ) : (
        <Icon as={FiMenu} boxSize={6} />
      )
    }
    {...rest}
  />
)
